<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p *ngIf="data?.message"><span [innerHtml]="data.message"></span></p>
  <p *ngIf="data?.subMessage"><span [innerHtml]="data.subMessage"></span></p>
  <p *ngIf="data?.param">{{data.param}}</p>
</div>
<div mat-dialog-actions [ngClass]="{'loading-container':data?.loading}">
  <button mat-button [mat-dialog-close]="'ok'" cdkFocusInitial *ngIf="data.okButtonText">{{data.okButtonText}}</button>
  <button mat-button [mat-dialog-close]="'opt1'" cdkFocusInitial *ngIf="data.opt1ButtonText">{{data.opt1ButtonText}}</button>
  <button mat-button [mat-dialog-close]="'opt2'" cdkFocusInitial *ngIf="data.opt2ButtonText">{{data.opt2ButtonText}}</button>
  <button mat-button [mat-dialog-close]="'cancel'" cdkFocusInitial *ngIf="data.cancelButtonText">{{data.cancelButtonText}}</button>
  <mat-spinner *ngIf="data?.loading" diameter="80"  class="alert-loading"></mat-spinner>
</div>
