<mat-drawer-container class="example-container mat-typography" autosize
                      [ngClass]="{'opened':isExpanded,'closed':!isExpanded}">
  <mat-drawer #drawer mode="side" disableClose="true" opened="true">
    <div class="app-menu-button-container">
      <button id="WA" class="whatsapp-button" mat-button>
        <a href="https://api.whatsapp.com/send?phone=+16469454855&text=Hey,%20can%20I%20get%20more%20info%20about%20your%20services?">
            <fa-icon [icon]="whatsAppIcon"></fa-icon>
            <span>Talk to us</span>
        </a>
      </button>
<!--      <span  class="app-menu-text">MENU</span>-->
    <div class="app-menu-logo">
      <button mat-button class="app-menu-button" (click)="toggleExpand()">
        <i class="material-icons">menu</i>
      </button>
      <div class="logo-container">
        <span (click)="goHome()"></span>
      </div>
    </div>
    </div>
    <mat-nav-list>
<!--      <mat-list-item *ngIf="(authService.isAuth | async)" class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'dashboard'}"-->
<!--                     (click)="goToPath('dashboard')">-->
<!--        <div class="nav-list-item-main">-->
<!--          <div matTooltip="Dashboard" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"-->
<!--               matTooltipPosition="right" class="nav-list-item-container">-->
<!--            <mat-icon mat-list-icon>dashboard</mat-icon>-->
<!--            <span class="list-item-text" *ngIf="isExpanded">Dashboard</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div></div>-->
<!--      </mat-list-item>-->
      <mat-list-item *ngIf="(authService.isAuth | async)" class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'activity'}"
                     (click)="goToPath('activity')">
        <div class="nav-list-item-main">
          <div matTooltip="Activity" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"
               matTooltipPosition="right" class="nav-list-item-container">
            <mat-icon mat-list-icon>favorite</mat-icon>
            <span class="list-item-text" *ngIf="isExpanded">Activity</span>
          </div>
        </div>
        <div></div>
      </mat-list-item>
      <mat-list-item *ngIf="(authService.isAuth | async)" class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'billing'}"
                     (click)="goToPath('billing')">
        <div class="nav-list-item-main">
          <div matTooltip="Payments" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"
               matTooltipPosition="right" class="nav-list-item-container">
            <mat-icon mat-list-icon>credit_card</mat-icon>
            <span class="list-item-text" *ngIf="isExpanded">Billing</span>
          </div>
        </div>
        <div></div>
      </mat-list-item>
      <mat-list-item *ngIf="(authService.isAuth | async)" class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'profile'}"
                     (click)="goToPath('profile')">
        <div class="nav-list-item-main">
          <div matTooltip="Profile" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"
               matTooltipPosition="right" class="nav-list-item-container">
            <mat-icon mat-list-icon>account_circle</mat-icon>
            <span class="list-item-text" *ngIf="isExpanded">Profile</span>
          </div>
        </div>
        <div></div>
      </mat-list-item>
      <mat-list-item *ngIf="(authService.isAuth | async)" class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'request'}"
                     (click)="goToPath('request')">
        <div class="nav-list-item-main">
          <div matTooltip="New Request" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"
               matTooltipPosition="right" class="nav-list-item-container">
            <mat-icon mat-list-icon>settings_suggest</mat-icon>
            <span class="list-item-text" *ngIf="isExpanded">New Request</span>
          </div>
        </div>
        <div></div>
      </mat-list-item>
<!--      <mat-list-item class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'settings'}"-->
<!--                     *ngIf="authService.isAuth"-->
<!--                     (click)="goToPath('settings')">-->
<!--        <div class="nav-list-item-main">-->
<!--          <div matTooltip="Settings" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"-->
<!--               matTooltipPosition="right" class="nav-list-item-container">-->
<!--            <mat-icon mat-list-icon>settings</mat-icon>-->
<!--            <span class="list-item-text" *ngIf="isExpanded">Settings</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div></div>-->
<!--      </mat-list-item>-->
<!--      <mat-list-item class="nav-list-item"-->
<!--                     (click)="goToPublic()">-->
<!--        <div class="nav-list-item-main">-->
<!--          <div matTooltip="Go to Website" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"-->
<!--               matTooltipPosition="right" class="nav-list-item-container">-->
<!--            <mat-icon mat-list-icon>home</mat-icon>-->
<!--            <span class="list-item-text" *ngIf="isExpanded">Go to Website</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div></div>-->
<!--      </mat-list-item>-->
      <mat-list-item class="nav-list-item" [ngClass]="{'selected-route':selectedRoute === 'login'}"
                     *ngIf="(authService.isAuth | async)"
                     (click)="logout()">
        <div class="nav-list-item-main">
          <div matTooltip="Logout" [matTooltipDisabled]="isExpanded" matTooltipClass="app-tooltip"
               matTooltipPosition="right" class="nav-list-item-container">
            <mat-icon class="logout-icon" mat-list-icon>login</mat-icon>
            <span class="list-item-text" *ngIf="isExpanded">Logout</span>
          </div>
        </div>
        <div></div>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>

  <mat-toolbar *ngIf="(authService.isAuth | async)" color="primary" [ngClass]="{'opened':isExpanded,'closed':!isExpanded}">
    <span class="selected-route">{{selectedRoute}}</span>
<!--    <div class="logo-login">-->
<!--      <div class="login-signup" [ngClass]="{'has-auth':(authService.isAuth | async)}">-->
<!--        <span *ngIf="(authService.isAuth | async)" (click)="logout()">Logout</span>-->
<!--      </div>-->
<!--    </div>-->

  </mat-toolbar>

  <mat-progress-bar *ngIf="show" mode="indeterminate"></mat-progress-bar>
  <router-outlet></router-outlet>

</mat-drawer-container>

