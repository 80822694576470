import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoaderService } from './services/loader/loader.service';
import { LoaderInterceptorService } from './services/loaderInterceptor/loader-interceptor.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UtilsService } from './services/utils/utils.service';
import { ModalsModule } from './modals/modals.module';
import { AnalyticsService } from './services/analytics/analytics.service';
import { AuthService } from './services/auth/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TokenInterceptor } from './services/auth/token.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { ThemeConfigService } from './services/theme-config/theme-config.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function configFactory(config: ThemeConfigService) {
  return () => config.load();
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    MatSidenavModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    ModalsModule,
    FontAwesomeModule,
    NgxHotjarModule.forRoot(environment.hotJar),
    NgxHotjarRouterModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MLZT7NH',
    }),
  ],
  providers: [AuthService, AnalyticsService, UtilsService, LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },ThemeConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ThemeConfigService],
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule {
}
