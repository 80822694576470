/* tslint:disable:no-string-literal */
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuth: Subject<boolean> = new Subject<boolean>();

  // private authUser: ReplaySubject<CognitoUser> = new ReplaySubject<CognitoUser>();

  constructor() {

    this.isAuthenticated();
  }

  public async getCurrentUser(): Promise<CognitoUser | any> {
    return Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        return user;
      })
      .catch((error) => {
        return null;
      });
  }

  public async providerLogin(userName: string, password: string): Promise<CognitoUser> {
    try {
      const user = await Auth.signIn(userName, password);
      console.log(user);
      this.isAuth.next(true);
      return user;
    } catch (error) {
      console.log('error signing in', error);
      this.isAuth.next(false);
      return Promise.reject(error);
    }
  }

  public async logout() {
    await Auth.signOut();
    localStorage.removeItem('client');
    this.isAuth.next(false);
  }

  public isAuthenticated(): Promise<boolean> {
    return Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        this.isAuth.next(true);
        return true;
      })
      .catch((error) => {
        console.log('error isAuthenticated:', error);
        this.isAuth.next(false);
        return false;
      });
  }

  public getClientToken(): any {
    return Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      if (user) {
        return user.getSignInUserSession()?.getIdToken().getJwtToken();
      }
      return null;
    });
  }
}
