import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, ResolveStart, Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './services/analytics/analytics.service';
import { LoaderService, LoaderState } from './services/loader/loader.service';
import { Subscription } from 'rxjs';
import { Client } from './types/client';
import { UtilsService } from './services/utils/utils.service';
import { environment } from '../environments/environment';
import { PageEnum } from './enums/page.enum';
import { filter } from 'rxjs/operators';
import { Amplify } from 'aws-amplify';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ThemeConfigService } from './services/theme-config/theme-config.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('animationFade', [
      state('close', style({
        opacity: 0,
      })),
      state('open', style({
        opacity: 1,
      })),
      transition('close <=> open', animate(500)),
    ]),
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  public show = false;
  public isExpanded = false;
  public client: Client;
  public selectedRoute: string;
  public backendUrl: string;
  private subscription: Subscription;

  public whatsAppIcon = faWhatsapp;

  constructor(private router: Router,
              private analyticsService: AnalyticsService,
              private loaderService: LoaderService,
              private activatedRoute: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              public themeService: ThemeConfigService,
              private utilsService: UtilsService,
              private tagManagerService: GoogleTagManagerService,
              public  authService: AuthService,
              private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
    Amplify.configure(this.themeService.themeConfig.awsConfig);
    if (environment.production) {
      this.tagManagerService.addGtmToDom();
    }
  }

  ngOnInit() {
    if (!this.utilsService.isSmallScreen()) {
      this.isExpanded = true;
    }
    this.router.events.pipe(
      filter(event => event instanceof ResolveStart)).subscribe(event => {
      const data: any = event;
      if (data.url) {
        if (data.url.includes('dashboard')) {
          this.selectedRoute = 'dashboard';
        } else if (data.url.includes('activity')) {
          this.selectedRoute = 'activity';
        } else if (data.url.includes('billing')) {
          this.selectedRoute = 'billing';
        } else if (data.url.includes('profile')) {
          this.selectedRoute = 'profile';
        } else if (data.url.includes('request')) {
          this.selectedRoute = 'request';
        } else {
          this.selectedRoute = 'activity';
        }
      }
      // console.log(value.snapshot.routeConfig.path);
    });
  }

  ngAfterViewChecked() {
    this.subscription = this.loaderService.loaderState
      .subscribe((currState: LoaderState) => {
        this.show = currState.show;
      });
    this.cdRef.detectChanges();
  }

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public async goHome() {
    // await this.analyticsService.logEvent('goHome', PageEnum.main, AnalyticsEventTypeEnum.click);
    this.router.navigate([PageEnum.homepage]);
    // this.isExpanded = false;
  }

  public async goToPath(path: string) {
    //  await this.analyticsService.logEvent(path, PageEnum.main, AnalyticsEventTypeEnum.click);
    this.router.navigate([path]);
    if (this.utilsService.isSmallScreen()) {
      this.isExpanded = false;
    }
  }

  public goToPublic() {
    location.href = this.themeService.themeConfig.siteUrl;
  }

  public async logout() {
    //   await this.analyticsService.logEvent('logout', PageEnum.main, AnalyticsEventTypeEnum.click);
    await this.authService.logout();
    this.goToPublic();
   // this.router.navigate([PageEnum.login]);
    // this.isExpanded = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
