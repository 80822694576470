// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hotJar: '',
  publicUrl: 'http://localhost:4200',
  backendUrl: 'https://dev-api.insta-likes.com',
  firebase: {
    apiKey: 'AIzaSyAiIsdXRgCQU-pnGI8A5ncECZFsPTsDCsQ',
    authDomain: 'digitalboost-dev.firebaseapp.com',
    projectId: 'digitalboost-dev',
    storageBucket: 'digitalboost-dev.appspot.com',
    messagingSenderId: '915306870495',
    appId: '1:915306870495:web:4c90414fc906d8566d5b6a',
    measurementId: 'G-LW9V675VBV'
  },
  "awsConfig": {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TsgxaDgdN",
    "aws_user_pools_web_client_id": "b75cn5dvg8drrstprj96j4a1b",
    "oauth": {},
    "cookieStorage": {
      "domain": "insta-likes.com",
      "secure": false
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
