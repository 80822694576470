import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PageEnum } from '../../enums/page.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().then(state => {
      if (state) {
        if (next.routeConfig.path === 'login' || next.routeConfig.path === 'signup') {
          return this.router.navigate([PageEnum.homepage]);
        }
        return true;
      } else {
        if (next.routeConfig.path === PageEnum.login ) {
          return true;
        }
        return this.router.navigate([PageEnum.login]);
      }
    });
  }

}
