import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ThemeConfig } from '../../types/theme.config';

@Injectable({
  providedIn: 'root'
})
export class ThemeConfigService {
  public themeBaseUrl: string;
  public themeConfig: ThemeConfig;
  public hostName: string;

  constructor(private httpClient: HttpClient, @Inject(DOCUMENT) document) {

  }

  public async load(): Promise<any> {
    this.hostName = window.location.hostname.toLowerCase();
    this.themeBaseUrl = `assets/themes/${this.hostName}`;
    // const loaded = this.isLoadedAlready();
    //  if (!loaded) {
    this.addStyleThemeLink();
   // this.addFavicon();
    //  }
    return this.httpClient
      .get(`${this.themeBaseUrl}/config.json`)
      .toPromise()
      .then(data => {
        // @ts-ignore
        this.themeConfig = data;
        window[`themeConfig`] = this.themeConfig;
        // if (!loaded) {
        this.addSEOMeta();
        this.setTitle();
        this.addSocialMeta();
        //  }
      });
  }

  public getImageUrl(name: string): string {
    return `${this.themeBaseUrl}/images/${name}`;
  }

  private isLoadedAlready() {
    const ss = document.styleSheets;
    for (let i = 0, max = ss.length; i < max; i++) {
      return ss[i].href === `${this.themeBaseUrl}/css/theme.css`;
    }
    return false;
  }

  private addSEOMeta() {
    const canonicalElm = document.createElement(`link`);
    canonicalElm.rel = 'canonical';
    canonicalElm.href = this.themeConfig.canonicalUrl;
    document.head.append(canonicalElm);
  }

  private addSocialMeta() {
    const metaOgTitleElm: any = document.createElement(`meta`);
    metaOgTitleElm.property = 'og:title';
    metaOgTitleElm.content = this.themeConfig.ogTitle;
    document.head.append(metaOgTitleElm);
    const metaOgUrlElm: any = document.createElement(`meta`);
    metaOgUrlElm.property = 'og:url';
    metaOgUrlElm.content = this.themeConfig.ogUrl;
    document.head.append(metaOgUrlElm);
    const metaOgDecElm: any = document.createElement(`meta`);
    metaOgDecElm.property = 'og:description';
    metaOgDecElm.content = this.themeConfig.ogDescription;
    document.head.append(metaOgDecElm);
    const metaOgSiteNameElm: any = document.createElement(`meta`);
    metaOgSiteNameElm.property = 'og:site_name';
    metaOgSiteNameElm.content = this.themeConfig.ogSiteName;
    document.head.append(metaOgSiteNameElm);
  }

  private addStyleThemeLink() {
    const stylesheetElm = document.createElement(`link`);
    stylesheetElm.rel = 'stylesheet';
    stylesheetElm.type = 'text/css';
    stylesheetElm.href = `${this.themeBaseUrl}/css/theme.css`;
    document.head.append(stylesheetElm);
  }

  private addFavicon() {
    const stylesheetElm = document.createElement('link');
    stylesheetElm.type = 'image/x-icon';
    stylesheetElm.rel = 'icon';
    stylesheetElm.href = `${this.themeBaseUrl}/images/icon.ico`;
    document.head.append(stylesheetElm);
    const stylesheetAppleElm = document.createElement('link');
    stylesheetAppleElm.type = 'image/x-icon';
    stylesheetAppleElm.rel = 'apple-touch-icon';
    stylesheetAppleElm.href = `${this.themeBaseUrl}/images/icon.ico`;
    document.head.append(stylesheetAppleElm);
  }

  private setTitle() {
    document.title = this.themeConfig.pageTitle;
  }
}
