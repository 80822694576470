import { Injectable } from '@angular/core';
import { PageEnum } from '../../enums/page.enum';
import { AnalyticsEventTypeEnum } from '../../enums/analytics.enum';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {

  }

  public async logEvent(text: string, page: PageEnum, type: AnalyticsEventTypeEnum) {
   // return this.analyticsService.logEvent(`${AnalyticsEventTypeEnum[type]}:${text}`, {'page':PageEnum[page]});
  }
}
