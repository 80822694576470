import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { AlertComponent } from '../../modals/alert/alert.component';

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

export const WisAnimation = [
  trigger('animationFade', [
    state('close', style({
      opacity: 0,
    })),
    state('open', style({
      opacity: 1,
    })),
    transition('close <=> open', animate(500)),
  ]),
  trigger('listAnimation', [
    transition('* => *', [ // each time the binding value changes
      query(':enter', [
        style({opacity: 0}),
        stagger(100, [
          animate('0.3s', style({opacity: 1}))
        ])
      ], {optional: true})
    ])
  ])
];

export interface ValidationResult {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private matDialog: MatDialog,
              private authService: AuthService,
              private router: Router,
              private translateService: TranslateService) {
  }

  public async showErrorPopup(error: any): Promise<void> {
    if (error && error.wis2bizStatus === 2) {
      const alertDialog = this.matDialog.open(AlertComponent, {
        height: '250px',
        width: '300px',
        data: {
          title: this.translateService.instant('VALIDATION.TITLE'),
          message: this.translateService.instant('VALIDATION.MESSAGE_UNAUTHORIZED_GENERAL'),
          okButtonText: this.translateService.instant('VALIDATION.CLOSE')
        },
      });
      alertDialog.afterClosed().subscribe(() => {
       // this.authService.logout();
        this.router.navigate(['/login']);
      });
    } else {
      const alertDialog = await this.matDialog.open(AlertComponent, {
        height: '250px',
        width: '300px',
        data: {
          title: this.translateService.instant('VALIDATION.ERROR_TITLE'),
          message: this.translateService.instant('VALIDATION.ERROR_MESSAGE'),
          okButtonText: this.translateService.instant('VALIDATION.CLOSE')
        },
      });
      alertDialog.afterClosed().subscribe(() => {
        return;
      });
    }
  }

  public validatePhoneNumber(formControl: FormControl) {
    let validAreaCode = false;
    let areCodes = ['02', '03', '04', '08', '09', '050', '051', '052', '053', '054', '055', '056', '058', '059', '071', '072', '073', '074', '076', '077', '078', '079'];
    if (formControl.value) {
      const shortCode = formControl.value.substr(0, 2);
      const longCode = formControl.value.substr(0, 3);
      validAreaCode = (areCodes.indexOf(shortCode) > -1) || (areCodes.indexOf(longCode) > -1);
    }
    const cell = /^[0]{1}[57]{1}[0-9]{8}$/;
    const land = /^[0]{1}[2-468-9]{1}[0-9]{7}$/;
    const validationObj: { validatePhoneNumber: { valid: boolean } } = {
      validatePhoneNumber: {
        valid: false
      }
    };
    if (((cell.test(formControl.value) || land.test(formControl.value)) && validAreaCode)) {
      return null;
    } else {
      return validationObj;
    }
  }
  public isSmallScreen() {
    return window.innerWidth <= 600;
  }
  public isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public convertFilterArrayToObject(filterList: string[] = []) {
    const obj = {};
    filterList.forEach(filter => {
      obj[filter] = true;
    });
    return obj;
  }

  public encodeEmail(email: string) {
    return encodeURIComponent(email).replace(/\./g, '%2E');
  }

  public validatePasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {notEven: true};
  }

  public passwordValidator(control: FormControl): ValidationResult {
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    let min6Chars = control.value.length >= 6;
    // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
    const valid = hasNumber && hasUpper && hasLower && min6Chars;
    if (!valid) {
      // return what´s not valid
      return {strong: true};
    }
    return null;
  }

  public hasCountryCode(value) {
    const countryCode = /^(\+)([1-9]{3})(\d{10})$/;
    return countryCode.test(value);
  }

  public setCountryCode(phoneNumber: string, countryCode: string) {
    if (!this.hasCountryCode(phoneNumber)) {
      phoneNumber = countryCode + phoneNumber;
    }
    return phoneNumber;
  }

  public convertArrayToObject(array, key) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };
}
