import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { from, Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleHeadersData(request, next));
  }

  private async handleHeadersData(req: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {
    const user = await this.authService.getCurrentUser();
    let changedReq = req;
    if(user){
       changedReq = req.clone({
        setHeaders: {
          uid: user.username
        }
      });
    }
    return next.handle(changedReq).toPromise();
  }
}
