export class DialogData {
  public title: string;
  public message: string;
  public subMessage: string;
  public param: string;
  public okButtonText: string;
  public loading: string;
  public opt1ButtonText: string;
  public opt2ButtonText: string;
  public cancelButtonText: string;
}
